import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ApplicationDetails from './forms/ApplicationDetails';
import PersonalInformation from './forms/PersonalInformation';
import AcademicDetails from './forms/AcademicDetails';
import Requirements from './forms/Requirements';
import ExamDetails from './forms/ExamDetails';
import Review from './forms/Review';
import Response from './Response';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ['Application Details', 'Personal Information', 'Academic Details', 'Requirements', 'Examination Details', 'Review Your Application'];
}

function getStepContent(step, data) {
  switch (step) {
    case 0:
      return (
        <ApplicationDetails
          applicationDetails={data.applicationDetails}
          setApplicationDetails={data.setApplicationDetails}
          campuses={data.campuses}
          courses={data.courses}
        />
      );
    case 1:
      return (
        <PersonalInformation
          personalInfo={data.personalInfo}
          setPersonalInfo={data.setPersonalInfo}
          provinces={data.provinces}
          towns={data.towns}
        />
      );
    case 2:
      return (
        <AcademicDetails
          academicDetails={data.academicDetails}
          setAcademicDetails={data.setAcademicDetails}
        />
      );
    case 3:
      return (
        <Requirements
          requirements={data.requirements}
          setRequirements={data.setRequirements}
        />
      );
    case 4:
      return(
        <ExamDetails
          examDetails={data.examDetails}
          setExaminationDetails={data.setExaminationDetails}
        />
      )
    case 5:
      return (
        <Review
          applicationDetails={data.applicationDetails}
          personalInfo={data.personalInfo}
          academicDetails={data.academicDetails}
          examDetails={data.examDetails}
          requirements={data.requirements}
          campuses={data.campuses}
          courses={data.courses}
          provinces={data.provinces}
          towns={data.towns}
        />
      );
    default:
      return 'UNKNOWN STEP';
  }
}

const Apply = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [options, setOptions] = useState({});
  const [isResponseOpen, setIsResponseOpen] = useState(false);
  const [response, setResponse] = useState({});
  // application details
  const [applicationDetails, setApplicationDetails] = useState({
    type: '',
    campus: 0,
    course1: 0,
    course2: 0,
    course3: 0
  })
  // personal information
  const [personalInfo, setPersonalInfo] = useState({
    last_name: '',
    first_name: '',
    middle_name: '',
    suffix_name: '',
    sex: '',
    dob: '',
    pob: '',
    citizenship: '',
    civil_status: '',
    province_id: 0,
    area_id: 0,
    house_no_street_brgy: '',
    zip_code: '',
    alt_address: '',
    contact_no: '',
    email: '',
    name_father: '',
    name_mother: '',
    address_parents: '',
    is_ip: '',
    is_pwd: '',
    income_status: ''
  });
  // academic details
  const [academicDetails, setAcademicDetails] = useState({
    track_strand: '',
    lrn: '',
    general_average: 0.0,
    last_school_attended: '',
    last_school_attended_address: '',
    date_graduated: '',
    honors_received: ''
  })
  // requirements
  const [requirements, setRequirements] = useState([]);
  // examination details
  const [examDetails, setExaminationDetails] = useState({
    venue: ''
  });

  useEffect(() => {
    async function fetchData() {
      const provinces = await (await fetch(process.env.REACT_APP_API +"/provinces")).json()
      const towns = await (await fetch(process.env.REACT_APP_API +"/towns")).json()
      const campuses = await (await fetch(process.env.REACT_APP_API +"/campuses")).json()
      const courses = await (await fetch(process.env.REACT_APP_API +"/courses")).json()
      setOptions({
        provinces,
        towns,
        campuses,
        courses
      })
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    setIsResponseOpen(true);
    var formData = new FormData();
    const data = {
      applicant: {
        ...personalInfo,
        ...academicDetails
      },
      application: {
        semcamp_id: applicationDetails.campus,
        semcamp_course_id: applicationDetails.course1,
        semcamp_course_id2: applicationDetails.course2,
        semcamp_course_id3: applicationDetails.course3,
        type: applicationDetails.type,
        exam_venue: examDetails.venue
      },
      dob: personalInfo.dob,
      lrn: academicDetails.lrn,
      date_graduated: academicDetails.date_graduated,
      province_id: personalInfo.province_id,
      area_id: personalInfo.area_id
    }
    
    formData.append('data', JSON.stringify(data));
    requirements.forEach((file, index) => {
      formData.append('files', file);
    });
    const requestOptions = {
      method: 'POST',
      body: formData
    }
   
    fetch(process.env.REACT_APP_API + '/application', requestOptions)
      .then(response => response.json())
      .then(data => setResponse(data));
  }
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography component={'div'}>
                {getStepContent(index, {
                  campuses: options.campuses,
                  courses: options.courses,
                  provinces: options.provinces,
                  towns: options.towns,
                  applicationDetails: applicationDetails,
                  setApplicationDetails: setApplicationDetails,
                  personalInfo: personalInfo,
                  setPersonalInfo: setPersonalInfo,
                  academicDetails: academicDetails,
                  setAcademicDetails: setAcademicDetails,
                  requirements: requirements,
                  setRequirements: setRequirements,
                  examDetails: examDetails,
                  setExaminationDetails: setExaminationDetails
                })}
              </Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {
                    activeStep === steps.length - 1
                      ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          className={classes.button}
                        >
                          Submit
                        </Button>
                      )
                      : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          Next
                        </Button>
                      )
                  }
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps are completed - your application has been sent.</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
      <Response open={isResponseOpen} setIsResponseOpen={setIsResponseOpen} data={response} setResponse={setResponse} />
    </div>
  );
}

export default Apply;
