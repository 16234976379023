import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
}));

const ExamDetails = (props) => {
  // const classes = useStyles();
  const { examDetails, setExaminationDetails } = props;
  
  const handleChange = (event) => {
    setExaminationDetails({
      ...examDetails,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div>
      <Typography variant="body2" color="textSecondary" paragraph>
        Examination Venue
      </Typography>
      <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="civil_status">
              Where would you like to take the entrance exam?
          </InputLabel>
            <Select
              labelId="venue"
              id="venue"
              value={examDetails.venue}
              onChange={handleChange}
              inputProps={{ name: "venue" }}
              displayEmpty
            >
              <MenuItem disabled value={''}><em>Select</em></MenuItem>
              <MenuItem value="Bohol Island State University - BINGAG-DAUIS Campus">Bohol Island State University - BINGAG-DAUIS Campus</MenuItem>
              <MenuItem value="Bohol Island State University - BALILIHAN Campus">Bohol Island State University - BALILIHAN Campus</MenuItem>
              <MenuItem value="Bohol Island State University - BILAR Campus">Bohol Island State University - BILAR Campus</MenuItem>
              <MenuItem value="Bohol Island State University - CALAPE Campus">Bohol Island State University - CALAPE Campus</MenuItem>
              <MenuItem value="Bohol Island State University - CLARIN Campus">Bohol Island State University - CLARIN Campus</MenuItem>
              <MenuItem value="Bohol Island State University - CANDIJAY Campus">Bohol Island State University - CANDIJAY Campus</MenuItem>
              <MenuItem value="Ubay National Science High School - Ubay, Bohol">Ubay National Science High School - Ubay, Bohol</MenuItem>
              <MenuItem value="San Jose National High School - Talibon, Bohol">San Jose National High School - Talibon, Bohol</MenuItem>
              <MenuItem value="Katipunan National High School - Carmen, Bohol">Katipunan National High School - Carmen, Bohol</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </div>
  );
}

export default ExamDetails;
